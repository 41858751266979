
import OfferCard from '@/components/offer_card'
import PageNavbar from '@/components/page_navbar'
import { number_to_currency } from '@/lib/helpers'
import LoadingSpinner from '@/components/loading_spinner'
import SupplierSelect from '@/components/supplier_select'

export default {
  components: {
    OfferCard,
    PageNavbar,
    LoadingSpinner,
    SupplierSelect
  }

  data: -> {
    selected_id: null
    current_offer: null
  }

  computed: {
    loading: ->
      @$store.state.offers.loading

    offers: ->
      @$store.state.offers.all

    combo_out_of_stock: ->
      @current_offer.out_of_stock_at && @current_offer.out_of_stock_at.length > 0
  }

  mounted: ->
    if @$store.getters['suppliers/current']
      @$store.dispatch('offers/fetch_supplier_combos')

  destroyed: ->
    @$store.commit('offers/reset')

  methods: {
    add_to_cart: ->
      @$store.commit('offers/set_current', @current_offer)

    number_to_currency: (cents) ->
      number_to_currency({ symbol: @$store.getters['application/currency'], cents: cents })

    set_current_offer: (id) ->
      @current_offer = @offers.find (offer) -> offer.id == id
  }

  watch: {
    offers: (offers) ->
      @selected_id = offers[0].id if offers.length > 0

    selected_id: (id) ->
      @set_current_offer(id)
  }
}
